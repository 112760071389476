<template>
  <div class="auth">
    <h1>{{ $t("auth.passwordReset") }}</h1>

    <form @submit="reset_password" class="form" method="post">
      <div class="form-wrap">
        <input v-model="user.email" class="form-input" type="email" name="email" id="email" placeholder="E-Mail" />
      </div>

      <p style="text-align: right">
        <input type="submit" :value="$t('auth.resetButton')" class="button button-ci button-round button-100" />
      </p>

      <div class="auth-meta">
        <p>{{ $t("auth.noReset") }}</p>
        <p class="sign_up">
          <router-link to="/auth/sign_in" class="link">{{ $t("auth.loginButton") }}</router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<style>
.form-wrap {
  position: relative;
}

.form-wrap span {
  position: absolute;
  right: 0;
  top: 0;
}

.form-wrap span a {
  color: #91979f;
  font-weight: 600;
}
</style>

<script>
import axios from "axios";

export default {
  name: "auth-sign-in",
  data() {
    return {
      user: {
        email: "",
      },
    };
  },
  methods: {
    reset_password: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/auth/password",
          {
            email: this.user.email,
          },
          { headers: {} }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("auth.resetNote"),
          });
          this.$router.push("/auth/sign_in");
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            text: error.response.data.errors[0],
          });
        });
    },
  },
  mounted() {},
};
</script>
